import ctl from "@netlify/classnames-template-literals";
import type { PageLayoutVariant } from "./index";

export const page = (variant: PageLayoutVariant) => {
  const base = ctl(`
  flex
  w-full
  flex-col
  items-center
  bg-white
  text-neutral-900
  rounded-t-xl
  relative
  -mt-3
`);

  const variantStyle = {
    default: ctl(`
      p-6
      md:pt-14
      md:px-24
      md:pb-24
    `),
    "full-width": ctl(``),
  };

  return `${base} ${variantStyle[variant]}`;
};

export const container = (variant: PageLayoutVariant) => {
  const base = ctl(`
  relative
  flex
  w-full
  h-full
  flex-col
`);

  const variantStyle = {
    default: ctl(`
      max-w-5xl
    `),
    "full-width": ctl(``),
  };

  return `${base} ${variantStyle[variant]}`;
};
